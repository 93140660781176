import React, { Component } from 'react'
import Scaning from '../components/Scaning'
import Layout from '../layouts/Layout'

export default class scan extends Component {
    render() {
        return (
            <Layout>
                <Scaning/>
            </Layout>
        )
    }
}
